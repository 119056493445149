<template>
  <section class="mt-4">
    <modal
      v-if="deleteWishlistModalShow"
      :closeFn="hideDeleteWishlistModal"
      closeBtn="cancel"
      :submitFn="deleteWishlist"
      submitBtn="ok"
    >
      Are you sure you want to delete wish list?
    </modal>

    <modal
      v-if="deleteWishlistItemModalShow"
      :closeFn="hideDeleteWishlistItemModal"
      closeBtn="cancel"
      :submitFn="deleteWishlistItem"
      submitBtn="ok"
    >
      Are you sure you want to delete wish list product?
    </modal>
    <modal
      v-if="redeemRewardModalShow"
      :closeFn="hideRedeemRewardsModal"
      closeBtn="cancel"
      :submitFn="redeemPoints"
      submitBtn="ok"
    >
      Are you sure you want to redeem ${{ $filters.numberFormat(userDetails.loyalty_details.dollars) }}?
    </modal>
    <modal
      v-if="changeEnrollmentModalShow"
      :closeFn="hideChangeEnrollmentModal"
      closeBtn="cancel"
      :submitFn="updateLoyaltyStatus"
      submitBtn="ok"
    >
      Are you sure you want to unenroll from the Petals Rewards?
    </modal>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div id="account-profile">
      <div class="row">
        <div class="col-md-9 pe-0">
          <p class="regular-p no-letter-spacing">
            Welcome back {{ userDetails.customer.first_name }}. If you are not
            {{ userDetails.customer.first_name }}
            {{ userDetails.customer.last_name }},
            <a href="#" @click="doLogout">CLICK HERE</a>
          </p>
          <h1 class="header text-center text-md-start px-0 mb-5">{{ userDetails.company.name }}</h1>
        </div>
        <aside id="column-right" class="col-md-3 hidden-xs">
          <div class="list-group">
            <a :href="`/change-password`" class="list-group-item">Password</a>
            <a href="/update-information" class="list-group-item">Update My Information</a>
            <a href="#" @click="doLogout" class="list-group-item">Logout</a>
          </div>
        </aside>
      </div>
      <div v-if="canViewLoyalty" id="loyalty-information" class="mt-4 mt-md-0">
        <h2 class="sub-header pb-0 mb-2 text-center text-md-start mt-0" >My Petals Rewards
          <div v-if="canChangeLoyaltyStatus" class="form-check form-switch ms-2 mb-0 d-inline-block align-middle--uppercase">
            <input id="can-enroll-loyalty" class="form-check-input mt-0" type="checkbox" v-model="isLoyaltyEnrolled" :checked="isLoyaltyEnrolled" @change="showChangeEnrollmentModalShow"/>
          </div>
        </h2>
        <div class="content-p p-0, m-0 mb-2 text-center text-md-start"><a href="/petals-rewards">Learn more</a></div>
        <div v-if="userDetails.loyalty_details.notification_email != null && userDetails.loyalty_details.notification_email != undefined" class="content-p p-0, m-0 mb-2 text-center text-md-start">
          Notification Email: 
          <template v-if="editLoyaltyEmailAddress == 1">
            <input type="email" class="form-control d-block d-md-inline mx-auto mx-md-0 mb-3 mb-md-0 text-center text-md-start" style="width: 300px;" id="loyalty-notification-email" name="loyalty_notification_email" @keyup="validateEmail" v-model="newLoyaltyEmail" />
            <button class="btn btn-theme" @click.prevent="updateLoyaltyEmail">Update</button>
            <span class="mx-2"></span>
            <button class="btn btn-theme" @click.prevent="viewLoyaltyEmail">Cancel</button>
            <div class="text-danger text-center text-md-start" v-if="displayLoyaltyEmailErrorMessage == 1">Please enter a valid email address</div>
          </template>
          <template v-else>
            {{ newLoyaltyEmail }} <span @click="editLoyaltyEmail" style="font-size: 20px;"><i class="fa-solid fa-pen-to-square ps-2"></i></span>
          </template>
        </div>
        <div class="container">
          <div class="row mb-5">
            <div class="col-12 col-md my-0 px-0 me-md-2" style="margin-bottom: 30px !important;">
              <div class="sub-header-3 border border-3 border-top-0 border-start-0 border-end-0 text-center fw-bold px-2 py-4" style="background-color:#ededed; margin-bottom: 0px;">Available Petals</div>
              <div class="sub-header-3 text-center px-2 py-4" style="background-color:#ededed; margin-top: 0px; margin-bottom: 0px;">{{ $filters.integerNumberFormat(userDetails.loyalty_details.available_points) }}</div>
              <template v-if="userDetails.loyalty_details.dollars > 0">
                <div class="text-center content-p py-0 my-3 bg-white">Equates to ${{ $filters.numberFormat(userDetails.loyalty_details.dollars) }}</div>
                <div v-if="canChangeLoyaltyStatus" class="text-center bg-white"><a @click.prevent="showRedeemRewardsModal" class="btn btn-theme w-50">Redeem</a></div>
              </template>
            </div>
            <div class="col-12 col-md sub-header-4 my-0 px-0 mx-md-2" style="padding-top: 65px;">
              <div class="border border-3 text-center fw-bold p-3" style="text-transform: none;">Pending Petals</div>
              <div class="border border-3 border-top-0 text-center p-3">{{ $filters.integerNumberFormat(userDetails.loyalty_details.pending_points) }}</div>
            </div>
            <div class="col-12 col-md sub-header-4 my-0 px-0 mx-md-2" style="padding-top: 65px;">
              <div class="border border-3 text-center fw-bold p-3" style="text-transform: none;">Expiring Petals <span style="font-size: 13px; letter-spacing: 1.5px"><Popper :arrow="true" :hover="true" content="Total Petals expiring within the next 14 days."><i class="fa-solid fa-circle-info info-icon"></i></Popper></span></div>
              <div class="border border-3 border-top-0 text-center p-3">{{ $filters.integerNumberFormat(userDetails.loyalty_details.expiring_points) }}</div>
            </div>
            <div class="col-12 col-md sub-header-4 my-0 px-0 mx-md-2" style="padding-top: 65px;">
              <div class="border border-3 text-center fw-bold p-3" style="text-transform: none;">Lifetime Petals</div>
              <div class="border border-3 border-top-0 text-center p-3">{{ $filters.integerNumberFormat(userDetails.loyalty_details.lifetime_points) }}</div>
            </div>
            <div class="col-12 col-md sub-header-4 my-0 px-0 ms-md-2" style="padding-top: 65px;">
              <div class="border border-3 text-center fw-bold p-3" style="text-transform: none;">Claimed Rewards</div>
              <div class="border border-3 border-top-0 text-center p-3">${{ $filters.numberFormat(userDetails.loyalty_details.claimed_rewards) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div id="tab-section">
        <tabs-js :tabs="tabs">
          <template v-slot:invoice-history>
            <div class="row">
              <div class="table-responsive">
                <h2 class="sub-header pb-0 mb-2 text-center text-md-start">
                  Invoice History - Past 30 Days
                  <p class="content-p d-block d-md-inline mt-2 mt-md-0 text-center text-md-start">
                    <a href="/account/invoice-history">VIEW MORE INVOICES</a>
                  </p>
                </h2>
                <p class="content-p">If you placed your order on-line, it might take up to 24 hours for your invoice to be entered and displayed on this page.</p>
                <table v-if="ordersCount > 0" class="table table-striped table-bordered text-center">
                  <thead class="bg-grey">
                    <tr>
                      <th>Invoice #</th>
                      <th>Invoice Date</th>
                      <th>PO #</th>
                      <th>Order Ref</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Status</th>
                      <th>Total</th>
                      <th>View</th>
                      <th>PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, index) in userDetails.orders" :key="index">
                      <td>
                        <a v-if="order.totalBoxes" :href="`/account/order-details/${order.id}`">{{ order.ecommerce ? "W" + order.number : order.number }}</a>
                        <span v-else>{{ order.number }}</span>
                      </td>
                      <td>{{ formatDateWithDash(order.shipDate) }}</td>
                      <td>{{ order.po }}</td>
                      <td>{{ order.orderRef }}</td>
                      <td>{{ order.shipCity }}</td>
                      <td>{{ order.shipState }}</td>
                      <td>{{ order.status }}</td>
                      <td>${{ order.total }}</td>
                      <td>
                        <div class="d-inline-flex">
                          <Popper :arrow="true" :hover="true" content="View">
                            <a v-if="order.totalBoxes" :href="`/account/order-details/${order.id}`"><i class="fa-regular fa-file-lines" aria-hidden="true"></i></a>
                          </Popper>
                        </div>
                      </td>
                      <td>
                        <div class="d-inline-flex">
                          <Popper :arrow="true" :hover="true" content="Download PDF">
                            <a href="#" @click.prevent=" downloadPdf(order.id, order.number)"><i class="fa-solid fa-file-pdf" aria-hidden="true"></i></a>
                          </Popper>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="mb-0 text-center text-md-start"><a href="/account/invoice-history">VIEW MORE INVOICES</a></p>
            </div>
          </template>
          <template v-slot:processing-online-orders>
            <div class="row">
              <div class="table-responsive">
                <h2 class="sub-header pb-0 mb-2 text-center text-md-start">Processing Online Orders - Up to 6 Months</h2>
                <p class="content-p">View your upcoming online orders in the pipeline here. If you placed your order online, it may take up to 24 hours for your order to display and may not include delivery or freight charges. Please be aware that orders may be divided across multiple invoices as they progress through our system to accommodate logistical needs. We'll promptly notify you of any potential changes to your orders. For more details on an order's status, please connect with your Sales Rep.</p>
                <table v-if="pendingOrdersCount > 0" class="table table-striped table-bordered text-center">
                  <thead class="bg-grey">
                    <tr>
                      <th>Truck Date</th>
                      <th>Prebook Number</th>
                      <th>PO #</th>
                      <th>Program</th>
                      <th>Carrier</th>
                      <th>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pending_order, index) in userDetails.pending_orders" :key="index">
                      <td>{{ formatDateWithDash(this.appendTime(pending_order.date)) }}</td>
                      <td><a :href="`/account/pending-order-details/${pending_order.number}/${pending_order.date}`">{{ pending_order.number }}</a></td>
                      <td>{{ pending_order.po }}</td>
                      <td>{{ pending_order.program }}</td>
                      <td>{{ pending_order.carrier_name }}</td>
                      <td>{{ pending_order.status }}</td>
                      <td>
                        <div class="d-inline-flex">
                          <Popper :arrow="true" :hover="true" content="View">
                            <a :href="`/account/pending-order-details/${pending_order.number}/${pending_order.date}`"><i class="fa-regular fa-file-lines" aria-hidden="true"></i></a>
                          </Popper>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-slot:invoice-credit-history>
            <div class="row">
              <div class="table-responsive">
                <h2 class="sub-header pb-0 mb-2 text-center text-md-start">
                  Invoice Credit History - Past 30 Days
                  <p class="content-p d-block d-md-inline mt-2 mt-md-0 text-center text-md-start">
                    <a href="/account/credit-history">VIEW MORE INVOICE CREDITS</a>
                  </p>
                </h2>
                <p class="content-p">It might take up to 24 hours for your invoice credit to be entered and displayed on this page.</p>
                <p v-if="creditsCount < 1">There are no invoice credits to display for the past 30 days.</p>
                <table v-if="creditsCount > 0" class="table table-striped table-bordered text-center">
                  <thead class="bg-grey">
                    <tr>
                      <th>Credit #</th>
                      <th>Date</th>
                      <th>Invoice #</th>
                      <th>Order Total</th>
                      <th>Total Credit</th>
                      <th>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(credit, index) in userDetails.credits" :key="index">
                      <td>
                        <a :href="`/account/credit-details/${credit.id}/` + formatDateLink(`${credit.creditDate}`)">{{ credit.creditNumber }}</a>
                      </td>
                      <td>{{ formatDateWithDash(credit.creditDate) }}</td>
                      <td>{{ credit.orderNumber }}</td>
                      <td>{{ credit.totalOrder }}</td>
                      <td>{{ credit.totalCredit }}</td>
                      <td>{{ credit.status }}</td>
                      <td>
                        <div class="d-inline-flex">
                          <Popper :arrow="true" :hover="true" content="View">
                            <a :href="`/account/credit-details/${credit.orderNumber}/` + formatDateLink(`${credit.creditDate}`)">
                              <i class="fa-regular fa-file-lines" aria-hidden="true"></i>
                            </a>
                          </Popper>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="mb-0 text-center text-md-start">
                <a href="/account/credit-history">VIEW MORE INVOICE CREDITS</a>
              </p>
            </div>
          </template>
          <template v-slot:petals-rewards v-if="this.canViewLoyalty">
            <div class="row">
              <div class="table-responsive">
                <h2 class="sub-header pb-0 mb-2 text-center text-md-start">
                  My Petals Rewards - Past 30 Days
                  <p class="content-p d-block d-md-inline mt-2 mt-md-0 text-center text-md-start">
                    <a href="/account/petals-rewards-history">VIEW PETALS REWARDS HISTORY</a>
                  </p>
                </h2>
                <p class="content-p">You've earned them. USE THEM!</p>
                <p v-if="loyaltyOrdersCount < 1">There are no petals rewards to display for the past 30 days.</p>
                <table v-if="loyaltyOrdersCount > 0" class="table table-striped table-bordered text-center">
                  <thead class="bg-grey">
                    <tr>
                    <th>Invoice<br>Date</th>
                    <th>Invoice #</th>
                    <th>Prebook #</th>
                    <th>Program</th>
                    <th>Sub-Total</th>
                    <th>Total</th>
                    <th>Petals</th>
                    <th>Redeemed</th>
                    <th>Available</th>
                    <th>Expiring<br>Date</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, index) in userDetails.loyalty_orders" :key="index">
                      <td>{{ formatDateWithDash(appendTimeForBrowser(order.invoice_date)) }}</td>
                      <td>{{ order.invoice_number }}</td>
                      <td>{{ order.prebook_number }}</td>
                      <td>{{ order.program_code }} <Popper :arrow="true" :hover="true" :content="`${order.program_name}`"><i class="fa-solid fa-circle-info info-icon"></i></Popper></td>
                      <td>${{ $filters.numberFormat(order.order_subtotal) }}</td>
                      <td>${{ $filters.numberFormat(order.order_total) }}</td>
                      <td>{{ $filters.integerNumberFormat(order.points_earned) }}</td>
                      <td>{{ $filters.integerNumberFormat(order.points_redeemed) }}</td>
                      <td>{{ order.points_available }}</td>
                      <td>{{ order.expiring_date != '-' ? formatDateWithDash(appendTimeForBrowser(order.expiring_date)) : order.expiring_date }}</td>
                      <td>{{ order.points_status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="mb-0 text-center text-md-start">
                <a href="/account/petals-rewards-history">VIEW PETALS REWARDS HISTORY</a>
              </p>
            </div>
          </template>
          <template v-slot:quotes>
            <div class="row">
              <div class="table-responsive">
                <h2 id="quotes-tab" class="sub-header pb-0 mb-2 text-center text-md-start">Quotes</h2>
                <div v-if="userDetails.quotes.length > 0">
                  <pagination-row :recordsCount="userDetails.total_quotes" :perPage="perPage" :currPage="quotesCurrentPage" @pageChange="changeQuotesPage" />
                  <table class="table table-striped table-bordered text-center">
                    <thead class="bg-grey">
                      <tr>
                        <th>Prebook Id</th>
                        <th>Date Submitted</th>
                        <th>Event Date</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(quote, index) in userDetails.quotes" :key="index">
                        <td><a :href="`/account/quote-details/${quote.id}`">{{ quote.prebook_number }}</a></td>
                        <td>{{ formatDateWithDash(quote.created_at) }}</td>
                        <td>{{ formatDateWithDash(appendTimeForBrowser(quote.quote_date)) }}</td>
                        <td>
                          <div class="d-inline-flex">
                            <Popper :arrow="true" :hover="true" content="View">
                              <a :href="`/account/quote-details/${quote.id}`">
                                <i class="fa-regular fa-file-lines" aria-hidden="true"></i>
                              </a>
                            </Popper>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination-row :recordsCount="userDetails.total_quotes" :perPage="perPage" :currPage="quotesCurrentPage" @pageChange="changeQuotesPage" />
                </div>
                <div v-else>Currently you do not have any quotes. To submit a quote visit our <a href="flower-library">Flower Library</a>.</div>
              </div>
            </div>
          </template>
          <template v-slot:product-planner-quotes>
            <div class="row">
              <div class="table-responsive">
                <h2 id="product-planner-tab" class="sub-header pb-0 mb-2 text-center text-md-start">Product Planner Quotes</h2>
                <div v-if="userDetails.ppl_quotes.total_ppl_quotes > 0">
                  <pagination-row :recordsCount="userDetails.ppl_quotes.total_ppl_quotes" :perPage="perPage" :currPage="pplCurrentPage" @pageChange="changePage" />
                  <table class="table table-striped table-bordered text-center">
                    <thead class="bg-grey">
                      <tr>
                        <th class="text-left">Event Name</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ppl_quote, index) in userDetails.ppl_quotes" :key="index">
                        <td class="text-left">
                          <a :href="`/account/product-planner-quote-details/${ppl_quote.id}`">{{ ppl_quote.event_name }}</a>
                        </td>
                        <td>{{ formatDateWithDash(appendTimeForBrowser(ppl_quote.event_date)) }}</td>
                        <td>
                          <div class="d-inline-flex">
                            <Popper :arrow="true" :hover="true" content="View">
                              <a :href="`/account/product-planner-quote-details/${ppl_quote.id}`">
                                <i class="fa-regular fa-file-lines" aria-hidden="true"></i>
                              </a>
                            </Popper>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination-row :recordsCount="userDetails.ppl_quotes.total_ppl_quotes" :perPage="perPage" :currPage="pplCurrentPage" @pageChange="changePage" />
                </div>
                <span v-else>Currently you do not have any product planner events. To submit one visit our <a href="/product-planner">Product Planner</a>.</span>
              </div>
            </div>
          </template>
          <template v-slot:wish-lists>
            <div class="row">
              <div class="table-responsive">
                <div>
                  <h2 class="sub-header pb-0 mb-2 float-start w-100 text-center text-md-start">Wish Lists</h2>
                  <button class="m-t-14 btn btn-theme float-end" v-if="wishlistItemToDelete.length" @click.prevent="showDeleteWishlistItemModal">Delete ({{ wishlistItemToDelete.length }})</button>
                </div>
                <div v-if="wishlistProductsCount > 0 || wishlistCount > 0">
                  <table v-if="wishlistProductsCount > 0" class="table table-striped table-bordered text-center">
                    <thead class="bg-grey">
                      <tr>
                        <th>
                          <input type="checkbox" :checked=" wishlistItemToDelete.length === userDetails.wishlist_products.length" @change="handleWliCbx" />
                        </th>
                        <th>Image</th>
                        <th class="text-left">Product Name</th>
                        <th>List Name</th>
                        <th>
                          Notify
                          <Popper class="d-inline" style="width: 10px" :arrow="true" :hover="true" content="You will receive an email notification when this product is available to purchase online.">
                            <i class="fa-solid fa-circle-question"></i>
                          </Popper>
                        </th>
                        <th class="quoteCol">Quote</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(wishlistItem, index) in userDetails.wishlist_products" :key="index">
                        <td>
                          <input type="checkbox" v-model="wishlistItemToDelete" :value="wishlistItem.id"/>
                        </td>
                        <td class="img-with-cbx">
                          <a :href="`/${wishlistItem.seo_url}`">
                            <img v-if="wishlistItem.image != null" :src="`${imagePath}` + wishlistItem.image" class="" :alt="wishlistItem.name" />
                            <img v-else :src="`${imagePath}` + getDefaultImage(wishlistItem.product_type_id)" class="" :alt="wishlistItem.name" />
                          </a>
                        </td>
                        <td class="text-left">
                          <a :href="`/${wishlistItem.seo_url}`">{{ wishlistItem.product_name }}</a>
                        </td>
                        <td>
                          <div class="d-inline-flex">
                            <search-autocomplete
                              class="mt-1"
                              :ref="`wishlist_item_${index}`"
                              v-model:w_name="wishlistItem.name"
                              :items="existingWishList"
                              :productId="index"
                              @updateWishlistName="addToWishList(index)">
                            </search-autocomplete>
                            <Popper :arrow="true" :hover="true" content="Save">
                              <a href="#" class="btn" @click.prevent="addToWishList(index)"><i class="fa-solid fa-floppy-disk"></i></a>
                            </Popper>
                          </div>
                        </td>
                        <td>
                          <input v-if="wishlistItem.notify_when_available == 1" checked type="checkbox" @click.prevent="updateNotify(index)" />
                          <input v-else type="checkbox" @click.prevent="updateNotify(index)" />
                        </td>
                        <td>
                          <div class="d-flex align-items-center justify-content-center" v-if="wishlistItem.is_quotable">
                            <input type="number" :min="wishlistItem.quantity" :step="wishlistItem.quantity" class="form-control m-r-5 width-70" v-model="quoteQuant[wishlistItem.id]" />
                            <span class="product_unit" v-if="wishlistItem.unit_id">{{ wishlistItem.unit_type }}</span>
                            <select v-else @change="setUnitType($event, wishlistItem)" class="form-select product_unit d-inline-block w-auto bg-transparent">
                              <option v-for="(fllUnitType, index) in fllUnitTypes" :key="index" :value="fllUnitType.id" :selected="fllUnitType.id == fllUnitTypes[0].id">{{ fllUnitType.unit }}</option>
                            </select>
                            <add-to-quote :quant="quoteQuant[wishlistItem.id]" :prods="[{...wishlistItem, name: wishlistItem.product_name, },]" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <button class="m-b-5 btn btn-theme float-end" v-if="wishlistToDelete.length" @click.prevent="showDeleteWishlistModal">Delete ({{ wishlistToDelete.length }})</button>
                  <table v-if="wishlistCount > 0" class="table table-striped table-bordered text-center">
                    <thead class="bg-grey">
                      <tr>
                        <th>
                          <input type="checkbox" :checked="wishlistToDelete.length === userDetails.wishlist.length" @change="handleWlCbx" />
                        </th>
                        <th class="text-left">Name</th>
                        <th class="quoteCol">Products Count</th>
                        <th class="quoteCol">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(wishlist, index) in userDetails.wishlist" :key="index">
                        <td>
                          <input type="checkbox" v-model="wishlistToDelete" :value="wishlist.name" />
                        </td>
                        <td class="img-with-cbx text-left">
                          <template v-if="!(`index-${index}` in editWishtListName)">
                            <a :href="`/wishlist/${wishlist.name}`">{{ wishlist.name }}</a>
                            <span @click.prevent.stop="toggleNameField(index, true)" class="m-l-5"><i class="fa-solid fa-pen-to-square"></i></span>
                          </template>
                          <template v-else>
                            <input type="text" @keydown.enter="saveName(index)" v-model="editWishtListName[`index-${index}`]"/>
                            <span @click.prevent.stop="saveName(index)" class="m-l-5"><i class="fa-solid fa-floppy-disk"></i></span>
                            <span @click.prevent.stop="toggleNameField(index, false)" class="m-l-5"><i class="fa-solid fa-rectangle-xmark"></i></span>
                          </template>
                        </td>
                        <td>{{ wishlist.products }}</td>
                        <td>
                          <div class="d-flex align-items-center ustify-content-center">
                            <Popper :arrow="true" :hover="true" content="View">
                              <a :href="`/wishlist/${wishlist.name}`" class="btn"><i class="fa-regular fa-file-lines"></i></a>
                            </Popper>
                            <move-to-quote v-bind:class="{invisible: !wishlist.is_quotable,}" :quant="1" :wishlist="wishlist.id" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <span>Currently you do not have any products in your wish list.</span>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:order-summary v-if="availableDates.length > 0 && userDetails.customer.display_summary">
            <div class="row">
              <div class="table-responsive">
                <h2 class="sub-header pb-0 mb-2 text-center text-md-start">Order Summary</h2>
                <p class="content-p">Please select date.</p>
                <div class="row mb-5">
                  <div class="col-md-3 m-auto">
                    <div class="md-form">
                      <label for="event_date" class="">Date</label>
                      <date-picker 
                        v-model:dateProp="deliveryDate" 
                        @pickerDateChanged="fetchHistory" 
                        :minDate="minDate" 
                        :maxDate="maxDate" 
                        :disabledDates="disabledDates"> 
                      </date-picker>
                    </div>
                  </div>
                </div>
                <table v-if="mmtOrdersCount > 0" class="table table-striped table-bordered text-center">
                  <thead class="bg-grey">
                    <tr>
                      <th>UPC</th>
                      <th class="text-left">Product</th>
                      <th>Total</th>
                      <th>Case Pack</th>
                      <th>Unit Cost</th>
                      <th>Case Cost</th>
                      <th>Retail Price</th>
                      <th class="ship-to-header" v-for="(shipToCode, index) in shipToCodes" :key="index">{{ shipToCode}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, index) in mmtOrders" :key="index">
                      <td>{{ order.upc }}</td>
                      <td class="text-left">{{ order.product_name }}</td>
                      <td>{{ order.total_quantities }}</td>
                      <td>{{ order.unit_count }}</td>
                      <td>${{ $filters.numberFormat(order.price) }}</td>
                      <td>${{ $filters.numberFormat(order.total) }}</td>
                      <td>${{ $filters.numberFormat(order.mmt_retail_price) }}</td>
                      <td v-for="(shipToCode, index) in shipToCodes" :key="index">{{ order[shipToCode] }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td v-for="(codeTotal, index) in codesTotal" :key="index">${{ $filters.numberFormat(codeTotal) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </tabs-js>
      </div>
      <div class="my-5">
        <h2 class="sub-header mb-2 text-center text-md-start">Notifications</h2>
        <div class="form-check form-switch">
          <input id="receive-abandoned-carts" class="form-check-input" type="checkbox" :value="abandonedCart" :checked="abandonedCart" @change="updateCustomerAbandonedCarts()"/>
          <label class="my-0 regular-p form-check-label" for="receive-abandoned-carts">Receive an email when you have items left in your cart and have not checked out.</label>
        </div>
      </div>
      <div class="row mt-4 mt-md-0">
        <div class="col-lg-6">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="no-letter-spacing">
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Buyer:</span>
                  {{ userDetails.customer.first_name }}
                  {{ userDetails.customer.last_name }}
                </p>
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Email for Invoices:</span>
                  {{ userDetails.company.emailForInvoices }}
                </p>
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Email for Statements:</span>
                  {{ userDetails.company.emailForStatements }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="no-letter-spacing">
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Salesperson:</span>
                  {{ userDetails.company.salespersonForFlowers.name }}
                  <a
                    :href="`mailto:${userDetails.company.salespersonForFlowers.email}`"
                    >{{ userDetails.company.salespersonForFlowers.email }}</a
                  >
                </p>
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Phone:</span>
                  {{ userDetails.company.salespersonForFlowers.phone }}
                </p>
                <p class="my-0 regular-p">
                  <span class="font-weight-bold">Fax:</span>
                  {{ userDetails.company.salespersonForFlowers.fax }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="billing-wrapper mb-4">
            <h2 class="sub-header pb-0 text-center text-md-start">Billing Information</h2>
            <div class="billing-container border p-4">
              <div class="no-letter-spacing">
                <p class="my-0 regular-p">
                  {{ userDetails.company.billAddress }} <br />
                  {{ userDetails.company.billCity }}, {{ userDetails.company.billState }},
                  {{ userDetails.company.billCountry }}, {{ userDetails.company.billZipCode }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="billing-wrapper mb-4">
            <h2 class="sub-header pb-0 text-center text-md-start">Shipping Information</h2>
            <div class="billing-container border p-4">
              <div class="no-letter-spacing">
                <p class="my-0 regular-p">
                  {{ userDetails.company.shipAddress }} <br />
                  {{ userDetails.company.shipCity}}, {{ userDetails.company.shipState }},
                  {{ userDetails.company.shipCountry }}, {{ userDetails.company.shipZipCode }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border mb-4">
        <div class="row p-4 no-letter-spacing">
          <div class="col-lg-6">
            <div class="billing-wrapper">
              <div class="billing-container">
                <div>
                  <p class="my-0 regular-p">Credit Limit: {{ userDetails.company.creditLimit }}</p>
                  <p class="my-0 regular-p">Terms: {{ userDetails.company.paymentTerms }}</p>
                  <p class="my-0 regular-p">Last Sale Date: {{ formatDateWithDash(userDetails.company.lastSaleDate) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="billing-wrapper">
              <div class="billing-container">
                <div>
                  <p class="my-0 regular-p">Available Credit: {{ userDetails.company.creditLimit }}</p>
                  <p class="my-0 regular-p">Customer Since: {{ formatDateWithDash(userDetails.company.customerSince) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  TabsJs,
  SearchAutocomplete,
  PaginationRow,
  Modal,
  AddToQuote,
  MoveToQuote,
  DatePicker,
  FlashAlert,
  PageMetadata,
} from "@/components";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { misc } from "@/mixins";
import _ from "lodash";
import Popper from "vue3-popper";
import axios from "axios";

export default {
  name: "AccountPage",
  mixins: [misc],
  components: {
    TabsJs,
    SearchAutocomplete,
    PaginationRow,
    Modal,
    Popper,
    AddToQuote,
    MoveToQuote,
    DatePicker,
    FlashAlert,
    PageMetadata,
  },
  data() {
    return {
      deleteWishlistModalShow: false,
      deleteWishlistItemModalShow: false,
      redeemRewardModalShow: false,
      changeEnrollmentModalShow: false,
      wishlistItemToDelete: [],
      metaKey: '',
      wishlistToDelete: [],
      fllUnitTypes: JSON.parse(localStorage.getItem("fllUnitTypes")),
      loader: "bars",
      isLoading: true,
      tabs: [
        {
          label: "Invoice History",
          id: "invoice-history",
        },
        {
          label: "Processing Online Orders",
          id: "processing-online-orders",
        },
        {
          label: "Invoice Credit History",
          id: "invoice-credit-history",
        },
        { label: "Quotes", id: "quotes" },
        {
          label: "Product Planner Quotes",
          id: "product-planner-quotes",
        },
        {
          label: "Wish List",
          id: "wish-lists",
        },
      ],
      fullPage: true,
      pplCurrentPage: 1,
      quotesCurrentPage: 1,
      perPage: 50,
      existingWishList: [],
      delivery_date: '',
      mmtOrder: [],
      mmtOrdersCount: 0,
      shipToCodes:[],
      mmtDates:[],
      availableDates: [],
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      codesTotal: [],
      deliveryDate: '',
      userDetails: {
        customer: {},
        company: {
          salespersonForFlowers: {},
        },
        orders: [],
        pending_orders: [],
        credits: [],
        loyalty_details: [],
        loyalty_orders: [],
        quotes: [],
        wishlist: [],
        ppl_quotes: [],
        wishlist_products: [],
        total_quotes: 0,
      },
      quoteQuant: {},
      editWishtListName: {},
      abandonedCart: 0,
      isLoyaltyEnrolled: 0,
      editLoyaltyEmailAddress: 0,
      newLoyaltyEmail: '',
      displayLoyaltyEmailErrorMessage: 0,
    };
  },
  created() {
    if (this.canViewLoyalty) {
      this.tabs = [
        {
          label: "Invoice History",
          id: "invoice-history",
        },
        {
          label: "Processing Online Orders",
          id: "processing-online-orders",
        },
        {
          label: "Invoice Credit History",
          id: "invoice-credit-history",
        },
        {
          label: "Petals Rewards",
          id: "petals-rewards",
        },
        { label: "Quotes", id: "quotes" },
        {
          label: "Product Planner Quotes",
          id: "product-planner-quotes",
        },
        {
          label: "Wish List",
          id: "wish-lists",
        },
      ];
    }
    this.fetchAccounts();
    if (this.$route.hash) {
      this.defaultTab = this.$route.hash.substring(1);
    }
  },
  mounted() {
    let tab = this.tabs[0];
    if (this.$route.hash) {
      const hash = this.$route.hash.substring(1);
      tab = _.find(this.tabs, ["id", hash]);
    }

    this.changeTab_s(tab.id);
    this.scrollToTab(`#${tab.id}`);

    localStorage.setItem("currentPage", "Account");
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("hideLoader", () => {
      this.hideLoader();
    });

    this.emitter.on("a2q", (payload) => {
      this.addToQuoteAfterMath(payload);
    });

    this.emitter.on("inputSelected", (payload) => {
      this.updateWishListName(payload);
    });

    this.emitter.on("update_w_name", (payload) => {
      this.updateWishListName(payload);
    });

    this.emitter.on("deletedProduct", () => {
      this.hideLoader();
    });
    this.$gtag.event({
      event_category: "page_view",
      event_action: "visit",
      event_label: localStorage.getItem("meta_title"),
      event_value: 1,
    });
  },
  unmounted() {
    this.emitter.off("a2q");
  },
  computed: {
    ...mapState(["activeTab"]),
    ...mapGetters(["isSalesRep", "isESales", "canChangeLoyaltyStatus", "canViewLoyalty"]),

    creditsCount() {
      return Object.getOwnPropertyNames(this.userDetails.credits).length;
    },
    loyaltyOrdersCount() {
      return Object.getOwnPropertyNames(this.userDetails.loyalty_orders).length;
    },
    ordersCount() {
      return Object.getOwnPropertyNames(this.userDetails.orders).length;
    },
    pendingOrdersCount() {
      return Object.getOwnPropertyNames(this.userDetails.pending_orders).length;
    },
    wishlistCount() {
      return this.userDetails.wishlist.length;
    },
    wishlistProductsCount() {
      return this.userDetails.wishlist_products.length;
    },
  },
  watch: {
    activeTab(tabId) {
      if (this.$route.hash) {
        this.$router.push({
          name: "Account",
          hash: `#${tabId}`,
        });
      }
    },
    "$route.hash": {
      handler: function (n) {
        n = n.substring(1);
        if (n !== this.activeTab) {
          this.changeTab_s(n);
          this.scrollToTab();
        }
      },
    },
    pplCurrentPage: function () {
      this.fetchPPLQuotes();
    },

    quotesCurrentPage: function () {
      this.fetchQuotes();
    },
  },
  methods: {
    ...mapActions(["fetchWishList", "doLogout"]),
    ...mapMutations({ changeTab_s: "changeTab" }),

    setUnitType(event, wishlistItem) {
      wishlistItem.unit_id_dd = parseInt(event.target.value, 10);
    },

    fetchHistory() {
      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/mmtOrders",
            {
              delivery_date: this.deliveryDate,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error == true) {
              this.mmtOrder= {};
              this.shipToCodes = {};
              this.mmtOrdersCount = 0;
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            } else {
              this.mmtOrders = response.data.mmt_order_products;
              this.codesTotal = this.mmtOrders.pop()
              this.shipToCodes = response.data.ship_to_codes;
              this.mmtOrdersCount = this.ObjectLength_Modern(this.mmtOrders);
              this.isLoading = false;
            }
          });
      } catch (ex) {
        console.log("Catch is here", ex);
        this.isLoading = false;
      }
    },
    ObjectLength_Modern(object) {
      return Object.keys(object).length;
    },
    toggleNameField(wishListItemIdx, showIt = false) {
      if (showIt) {
        this.editWishtListName[`index-${wishListItemIdx}`] =
          this.userDetails.wishlist[wishListItemIdx].name;
      } else {
        delete this.editWishtListName[`index-${wishListItemIdx}`];
      }
    },
    saveName(wishListItemIdx) {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/renameWishList",
          {
            old_name: this.userDetails.wishlist[wishListItemIdx].name,
            new_name: this.editWishtListName[`index-${wishListItemIdx}`],
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(() => {
          this.isLoading = false;
          location.reload();
        });
    },
    scrollToTab() {
      if (this.$route.hash) {
        setTimeout(() => {
          const elem = document.getElementById('tab-section');
          if (elem) {
            const rect = elem.getBoundingClientRect();
            window.scrollTo(
              0, rect.top + window.scrollY - document.getElementById("header-top-menu-bar").offsetHeight
            );
          }
        }, 1500);
        
      }
    },

    changePage(pageNumb) {
      this.pplCurrentPage = pageNumb;
    },

    formatDateLink(dateString) {
      if (!dateString) {
        return "";
      }
      const rawDate = new Date(dateString);
      return (
        rawDate.getFullYear() +
        "-" +
        (rawDate.getMonth() + 1) +
        "-" +
        rawDate.getDate()
      );
    },

    hideDeleteWishlistModal() {
      this.wishlistToDelete = [];
      this.deleteWishlistModalShow = false;
    },

    showDeleteWishlistModal() {
      this.deleteWishlistModalShow = true;
    },

    handleWliCbx(event) {
      if (event.target.checked) {
        const ids = [];
        this.userDetails.wishlist_products.forEach((arr) => {
          ids.push(arr.id);
        });
        this.wishlistItemToDelete = ids;
      } else {
        this.wishlistItemToDelete = [];
      }
    },

    handleWlCbx(event) {
      if (event.target.checked) {
        const names = [];
        this.userDetails.wishlist.forEach((arr) => {
          names.push(arr.name);
        });
        this.wishlistToDelete = names;
      } else {
        this.wishlistToDelete = [];
      }
    },

    hideDeleteWishlistItemModal() {
      this.wishlistItemToDelete = [];
      this.deleteWishlistItemModalShow = false;
    },

    showDeleteWishlistItemModal() {
      this.deleteWishlistItemModalShow = true;
    },

    changeQuotesPage(pageNumb) {
      this.quotesCurrentPage = pageNumb;
    },

    fetchPPLQuotes() {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/fetchProfilePplQuotes",
          {
            page_number: this.pplCurrentPage,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.userDetails.ppl_quotes = response.data.ppl_quotes.ppl_quotes;
          this.userDetails.ppl_quotes.total_ppl_quotes =
            response.data.ppl_quotes.total_ppl_quotes;
          this.isLoading = false;
        });
    },

    fetchQuotes() {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/fetchProfileQuotes",
          {
            page_number: this.quotesCurrentPage,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.userDetails.quotes = response.data.quotes.quotes;
          this.userDetails.quotes_total = response.data.quotes.total_quotes;
          this.isLoading = false;
        });
    },

    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    fetchAccounts() {
      this.isLoading = true;
      try {
        axios
          .get(process.env.VUE_APP_API_BASE + "auth/accounts", 
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            // changes for order summary date
            if (response.data.mmt_disabled_dates != '' && response.data.mmt_disabled_dates != undefined) {
              response.data.mmt_disabled_dates.forEach((disabledDate) => {
                const formatted = this.appendTimeForBrowser(disabledDate);
                this.disabledDates.push({
                  start: formatted,
                  end: formatted,
                  date: disabledDate,
                });
              });
            }

            if (response.data.mmt_min_date != '' && response.data.mmt_min_date != undefined) {
              this.minDate = this.appendTimeForBrowser(response.data.mmt_min_date);
            }

            if (response.data.mmt_max_date != '' && response.data.mmt_max_date != undefined) {
              this.maxDate = this.appendTimeForBrowser(response.data.mmt_max_date);
            }

            if (response.data.mmt_order_delivery_dates != '' && response.data.mmt_order_delivery_dates != undefined) {
              this.availableDates = response.data.mmt_order_delivery_dates;
            }

            // code end for dates
            this.userDetails.customer = response.data.customer;
            this.userDetails.company = response.data.company;
            this.abandonedCart = this.userDetails.customer.abandoned_carts;
            this.isLoyaltyEnrolled = this.userDetails.customer.can_enroll_loyalty;
            this.userDetails.orders = response.data.orders;
            this.userDetails.pending_orders = response.data.pending_orders;
            this.userDetails.credits = response.data.credits;
            this.userDetails.loyalty_details = response.data.loyalty_details;
            this.userDetails.loyalty_orders = response.data.loyalty_orders;
            this.userDetails.ppl_quotes = response.data.ppl_quotes.ppl_quotes;
            this.userDetails.ppl_quotes.total_ppl_quotes = response.data.ppl_quotes.total_ppl_quotes;
            this.userDetails.wishlist = response.data.wish_lists;
            this.userDetails.wishlist_products = response.data.wish_lists_products;
            this.userDetails.quotes = response.data.quotes.quotes;
            this.userDetails.total_quotes = response.data.quotes.total_quotes;
            if (this.userDetails.wishlist.length) {
              let wishListNames = Array();
              let wishLists = this.userDetails.wishlist;
              wishLists.forEach((wish, index) => {
                if (this.$refs["wishlist_item_" + index] != undefined) {
                  this.$refs["wishlist_item_" + index].resetResult();
                }

                if (wish.name) wishListNames.push(wish.name);
              });

              this.existingWishList = wishListNames;
            }

            if (this.userDetails.wishlist_products.length) {
              this.userDetails.wishlist_products.forEach((wish, index) => {
                this.quoteQuant[wish.id] = wish.quantity;
                if (this.$refs["wishlist_item_" + index] != undefined) {
                  this.$refs["wishlist_item_" + index].resetResult();
                }
              });
            }

            if(this.availableDates.length > 0 && this.userDetails.customer.display_summary) {
              this.tabs.push(
                {
                  label: "Order Summary",
                  id: "order-summary",
                }
              )
            }
            this.newLoyaltyEmail = this.userDetails.loyalty_details.notification_email;
            this.validateEmail();
            this.isLoading = false;
          });
      } catch {
        this.isLoading = false;
      }
    },
    updateNotify(rowId) {
      this.showLoader();
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/updateWishListName",
          {
            notify: this.userDetails.wishlist_products[rowId]
              .notify_when_available
              ? 0
              : 1,
            wishlist: this.userDetails.wishlist_products[rowId].id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          let level = "error";
          let message = "";
          if (response.data.error == false) level = "success";
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.emitter.emit("displayFlash", payload);
            this.$router.go();
          }
        });
    },
    addToWishList(rowId) {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/updateWishListName",
          {
            notify:
              this.userDetails.wishlist_products[rowId].notify_when_available,
            name: this.userDetails.wishlist_products[rowId].name,
            wishlist: this.userDetails.wishlist_products[rowId].id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.isLoading = false;
          // let level = "error";
          // let message = "";
          // if (response.data.error == false) level = "success";
          // message = response.data.message;
          // let payload = { message: message, level: level };
          // if (response.data.error == false) {
          //   this.$emit("displayFlash", payload);
          //   this.$router.go();
          // }

          let level = "error";
          let message = "";
          if (response.data.error == false) level = "success";
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.fetchAccounts();
            // this.fetchWishList();
          }
          this.emitter.emit("displayFlash", payload);
          this.isLoading = false;
        });
    },

    deleteWishlistItem() {
      this.deleteWishlistItemModalShow = false;
      this.showLoader();
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/deleteWishListItem",
          {
            wishlistId: this.wishlistItemToDelete,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          let level = "error";
          let message = "";
          if (response.data.error == false) level = "success";
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.emitter.emit("deleteFromWishlist", payload);
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          }
          this.$emit("displayFlash", payload);
        });
    },

    deleteWishlist() {
      this.deleteWishlistModalShow = false;
      this.showLoader();
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/deleteWishList",
          {
            wishlistName: this.wishlistToDelete,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          let level = "error";
          let message = "";
          if (response.data.error == false) {
            level = "success";
            this.wishlistToDelete = [];
          }
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.fetchAccounts();
            this.fetchWishList();
          }
          this.emitter.emit("displayFlash", payload);
        });
    },

    updateWishListName(payload) {
      this.userDetails.wishlist_products[payload.wishlistId].name =
        payload.value;
    },

    downloadPdf(orderId, invoiceNumber) {
      this.isLoading = true;

      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getOrderPdf`,
          {
            order_id: orderId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: "arraybuffer",
            dataType: "blob",
          }
        )
        .then((responseBlob) => {
          if (responseBlob.headers['content-type'] === 'application/json'){
            let response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(responseBlob.data)));
            if (response.error) {
              let message = response.message;
              let payload = { message: message, level: 'error' };
              this.emitter.emit("displayFlash", payload);
            }
          } else {
            const file = new Blob([responseBlob.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            let fileName = "invoice #" + invoiceNumber;
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `${fileName}.pdf`;
            link.click();
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    addToQuoteAfterMath(response) {
      this.triggerFlashAlert(response.msg, response.level);
    },

    updateCustomerAbandonedCarts() {
      this.isLoading = true;
      axios
        .post(process.env.VUE_APP_API_BASE + "auth/updateCustomerAbandonedCart", 
        {
            is_sales_rep: this.isSalesRep,
            is_e_sales: this.isESales,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          document.getElementById("receive-abandoned-carts").checked = response.data.receive_abandoned_carts;
          let level = "error";
          if (response.data.error == false) {
            level = "success";
          }
          let message = response.data.message;
          let payload = { message: message, level: level };
          this.emitter.emit("displayFlash", payload);
        });
      
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
    },
    updateLoyaltyStatus() {
      this.isLoading = true;
      axios
        .post(process.env.VUE_APP_API_BASE + "auth/changeLoyaltySignUp", 
        {
          status: this.isLoyaltyEnrolled,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          document.getElementById("receive-abandoned-carts").checked = response.data.receive_abandoned_carts;
          let level = "error";
          if (response.data.error == false) {
            level = "success";
          }
          let message = response.data.message, payload = { message: message, level: level };
          this.emitter.emit("displayFlash", payload);
          let tabId = '';
          if (window.location.href.split('#').length > 1) {
            if (window.location.href.split('#')[1].toLowerCase() == 'petals-rewards') {
              history.replaceState({}, document.title, window.location.href.split('#')[0]);
              tabId = 'invoice-history';
            } else {
              tabId = window.location.href.split('#')[1].toLowerCase();
            }
          }

          if (this.tabs.some(e => e.id === 'petals-rewards')) {
            this.tabs.splice(3,1);
            if (this.tabs.some(e => e.id === tabId)) {
              this.changeTab_s('invoice-history');
              setTimeout(() => {
                this.changeTab_s(tabId);
              }, 200);
            }
          }

          let customer = this.loggedinUserDetails;
          if (response.data.customer) {
            customer.can_enroll_loyalty = response.data.customer.can_enroll_loyalty
            customer.is_enrolled_loyalty = response.data.customer.is_enrolled_loyalty
            customer.loyalty_status = response.data.customer.loyalty_status
            localStorage.setItem("loggedinUser", JSON.stringify(customer));
          }
          this.isLoading = false;
        });

        this.isLoading = false;
        this.hideChangeEnrollmentModal();
    },
    updateLoyaltyEmail() {
      if (this.displayLoyaltyEmailErrorMessage == 0) {
        this.isLoading = true;
        axios
          .post(process.env.VUE_APP_API_BASE + "auth/changeLoyaltyEmail", 
          {
            email: this.newLoyaltyEmail,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            document.getElementById("receive-abandoned-carts").checked = response.data.receive_abandoned_carts;
            let level = "error";
            if (response.data.error == false) {
              level = "success";
            }
            let message = response.data.message, payload = { message: message, level: level };
            this.emitter.emit("displayFlash", payload);

            let customer = this.loggedinUserDetails;
            if (response.data.customer) {
              customer.can_enroll_loyalty = response.data.customer.can_enroll_loyalty
              customer.is_enrolled_loyalty = response.data.customer.is_enrolled_loyalty
              customer.loyalty_status = response.data.customer.loyalty_status
              localStorage.setItem("loggedinUser", JSON.stringify(customer));
            }
            this.newLoyaltyEmail = response.data.customer.notification_email;
            this.validateEmail();
            this.isLoading = false;
          });

        this.viewLoyaltyEmail();
        this.isLoading = false;
      }
    },
    redeemPoints(){
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/redeemPoints", 
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        ).then((response) => {
          let level = "error";
          if (response.data.error == false) {
            level = "success";
            this.userDetails.loyalty_details = response.data.loyalty_details;
            this.userDetails.loyalty_orders = response.data.loyalty_orders;
          }
          let message = response.data.message;
          let payload = { message: message, level: level };
          this.emitter.emit("displayFlash", payload);
          this.isLoading = false;
        }).catch((err) => {
          console.log('An error occurred while retriving the customer information. ', err);
          this.isLoading = false;
        });
        this.hideRedeemRewardsModal();
    },
    hideRedeemRewardsModal() {
      this.redeemRewardModalShow = false;
    },

    showRedeemRewardsModal() {
      this.redeemRewardModalShow = true;
    },

    hideChangeEnrollmentModal() {
      this.changeEnrollmentModalShow = false;
      this.isLoyaltyEnrolled = 1;
    },

    showChangeEnrollmentModalShow() {
      this.changeEnrollmentModalShow = true;
    },
    viewLoyaltyEmail() {
      this.editLoyaltyEmailAddress = 0;
    },
    editLoyaltyEmail() {
      this.editLoyaltyEmailAddress = 1;
    },
    validateEmail() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.newLoyaltyEmail = String(this.newLoyaltyEmail).toLocaleLowerCase();
      if (!emailRegex.test(this.newLoyaltyEmail)) {
        this.displayLoyaltyEmailErrorMessage = 1;
      } else {
        this.displayLoyaltyEmailErrorMessage = 0;
      }
    },
  },
};
</script>
<style>
.tab-content {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px;
}
button.nav-link,
button.nav-link:hover {
  color: #f48062;
}
</style>
<style scoped>
.h-25 {
  height: 25px;
}
.p0-r {
  padding-right: 0px !important;
}
.p0-l {
  padding-left: 0px !important;
}

.p0-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.font-weight-bold {
  font-weight: bold;
}
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
.header {
  padding-top: 80px;
  margin-bottom: 8px;
}
.quoteCol {
  width: 200px;
}

span.product_unit {
  text-transform: capitalize;
  padding-top: 8px;
  padding-left: 8px;
  width: 75px;
  text-align: left;
}
.width-70 {
  width: 70px;
}

.w-100 {
    width: 100%!important;
}
.align-middle--uppercase{
  min-height: 1em;
  vertical-align: middle;
  margin-top: 1px;
}
.align-middle--uppercase .form-check-input{
  margin-top: 0;
    height: 0.8em;
}
</style>
